import type { Product } from '@winestyle/api-client/src/ts/api/catalog/v1/product_pb.js'
import { getConfigJSON } from '@/utils/product-config'

export type ProductCarouselLink = {
  title: string
  href: string
}

export const productCarousels: Record<string, ProductCarouselLink[]> = {
  wine: [
    {
      title: '[MOCKED] Вина по акции',
      href: '/wine/discount/'
    },
    {
      title: '[MOCKED] Лучшие до 1000\u00A0₽',
      href: '/wine/cheap-and-best/'
    },
    {
      title: '[MOCKED] Топ-200 Италии',
      href: '/wine/italy/toscana90up/'
    },
    {
      title: '[MOCKED] Бестселлеры',
      href: '/wine/bestsellers/'
    },
    {
      title: '[MOCKED] Лучшие на Wine Spectator',
      href: '/wine/wine-spectator/'
    },
    {
      title: '[MOCKED] Вина 4* и выше',
      href: '/wine/best/'
    }
  ],
  'champagnes-and-sparkling': [
    {
      title: '[MOCKED] Шампанское по акции',
      href: '/champagnes-and-sparkling/discount/'
    },
    {
      title: '[MOCKED] Лучшие до 1000\u00A0₽',
      href: '/champagnes-and-sparkling/cheap-and-best/'
    },
    {
      title: '[MOCKED] Игристые вина Италии',
      href: '/champagnes-and-sparkling/asti/'
    },
    {
      title: '[MOCKED] Дорогое шампанское',
      href: '/champagnes-and-sparkling/expensive/'
    },
    {
      title: '[MOCKED] Игристые вина с рейтингом 90+',
      href: '/champagnes-and-sparkling/90p/'
    },
    {
      title: '[MOCKED] Игристые вина 4* и выше',
      href: '/champagnes-and-sparkling/80p/'
    }
  ],
  whisky: [
    {
      title: '[MOCKED] Виски по акции',
      href: '/whisky/discount/'
    },
    {
      title: '[MOCKED] Лучшие до 1000\u00A0₽',
      href: '/whisky/1000rub/'
    },
    {
      title: '[MOCKED] С сувениром',
      href: '/whisky/souvenir/'
    },
    {
      title: '[MOCKED] Виски от 50000\u00A0₽',
      href: '/whisky/50000rub/'
    },
    {
      title: '[MOCKED] Виски в подарочной упаковке',
      href: '/whisky/inbox/'
    }
  ],
  cognac: [
    {
      title: '[MOCKED] Коньяк по акции',
      href: '/cognac/discount/'
    },
    {
      title: '[MOCKED] Лучшие до 1000\u00A0₽',
      href: '/cognac/1000rub/'
    },
    {
      title: '[MOCKED] Коньяк с выдержкой 10 лет',
      href: '/cognac/10y/'
    },
    {
      title: '[MOCKED] Коньяк класса XO',
      href: '/cognac/xo/'
    },
    {
      title: '[MOCKED] Коньяк в подарочной упаковке',
      href: '/cognac/inbox/'
    }
  ],
  vodka: [
    {
      title: '[MOCKED] Водка по акции',
      href: '/vodka/all/'
    },
    {
      title: '[MOCKED] Водка Absolut',
      href: '/vodka/absolut/'
    },
    {
      title: '[MOCKED] Классическая водка',
      href: '/vodka/classical/'
    },
    {
      title: '[MOCKED] Водка премиум класса',
      href: '/vodka/premium/'
    }
  ],
  beer: [
    {
      title: '[MOCKED] Баварское пиво',
      href: '/beer/bavaria/'
    },
    {
      title: '[MOCKED] Пиво до 100\u00A0₽',
      href: '/beer/lt100rub/'
    },
    {
      title: '[MOCKED] Элитное пиво',
      href: '/beer/elite/'
    },
    {
      title: '[MOCKED] Пиво в бочонке',
      href: '/beer/mini-keg/'
    },
    {
      title: '[MOCKED] Живое пиво',
      href: '/beer/live/'
    }
  ],
  cider: [
    {
      title: '[MOCKED] Яблочный сидр',
      href: '/cider/tag_apples/'
    },
    {
      title: '[MOCKED] Французкий сидр',
      href: '/cider/france/'
    },
    {
      title: '[MOCKED] Сидр объемом 0.75л',
      href: '/cider/750ml/'
    },
    {
      title: '[MOCKED] Грушевый сидр',
      href: '/cider/tag_pear/'
    }
  ],
  liqueur: [
    {
      title: '[MOCKED] Лучшие до 1000\u00A0₽',
      href: '/liqueur/500-1000rub/500rub/best-liqueur_ll/'
    },
    {
      title: '[MOCKED] Сливочный ликёр',
      href: '/liqueur/cream/'
    },
    {
      title: '[MOCKED] Ликёр 20%',
      href: '/liqueur/20alc/'
    },
    {
      title: '[MOCKED] Ликёр по акции',
      href: '/liqueur/discount/'
    }
  ],
  water: [
    {
      title: '[MOCKED] Вода до 100\u00A0₽',
      href: '/water/100rub/'
    },
    {
      title: '[MOCKED] Вода San Benedetto',
      href: '/water/san-benedetto/'
    },
    {
      title: '[MOCKED] Вода VOSS',
      href: '/water/voss/'
    },
    {
      title: '[MOCKED] Австрийская вода',
      href: '/water/austria/'
    }
  ],
  juice: [
    {
      title: '[MOCKED] Яблочный сок',
      href: '/juice/tag_apples/'
    },
    {
      title: '[MOCKED] Ананасовый сок',
      href: '/juice/tag_pineapple/'
    },
    {
      title: '[MOCKED] Сок до 200\u00A0₽',
      href: '/juice/200rub/'
    },
    {
      title: '[MOCKED] Французские соки',
      href: '/juice/france/'
    }
  ],
  accessory: [
    {
      title: '[MOCKED] До 1000\u00A0₽',
      href: '/accessory/500-1000rub/'
    },
    {
      title: '[MOCKED] Штопоры',
      href: '/accessory/corkscrew/'
    },
    {
      title: '[MOCKED] Для вина',
      href: '/accessory/for-wine/'
    },
    {
      title: '[MOCKED] Аксессуары от 1\u00A0500',
      href: '/accessory/1500-3000rub/'
    }
  ]
}

const carouselFields: Array<keyof Product.AsObject> = [
  'id',
  'code',
  'pricing',
  'pb_package',
  'feature',
  'social',
  'expertRatesList',
  'name',
  'imagesList',
  'category',
  'characteristicsMap',
  'description'
]

export const productDataForCarousel = (products: Product.AsObject[]) => {
  return products.map(product => Object.fromEntries(carouselFields.map((key) => {
    if (key === 'characteristicsMap') {
      const configCategory = product.category === 'champagnes-and-sparkles' ? 'champagnes' : product.category
      const characteristics = getConfigJSON(configCategory)?.grid.accent
        .flat()
        .map(el => Object.keys(el))
        .flat() ??
        []

      return [key, product[key].filter(el => characteristics.includes(el[0]) || el[0] === 'region') ?? []]
    }

    return [key, product[key]]
  })))
}
